import React from "react"
import InvestigationIntro from "../components/InvestigationIntro/InvestigationIntro"
import InvestigationResults from "../components/InvestigationResults/InvestigationResults"
import NavigationBar from "../components/global/NavigationBar/NavigationBar"
import SiteFooter from "../components/global/SiteFooter/SiteFooter"
import InvestigationCVTool from "../components/InvestigationCVTool/InvestigationCVTool"
import InvestigationDetails from "../components/InvestigationDetails/InvestigationDetails"
import Layout from "./../components/Layout/Layout"
import { graphql, useStaticQuery } from "gatsby"

const Investigation = () => {
  const data = useStaticQuery(graphql`
    query investigationQuery {
      prismicBlindcvInvestigationPage {
        data {
          analytics {
            document {
              ... on PrismicBlindcvPageAnalytics {
                data {
                  event
                  page_title
                  page_type
                }
              }
            }
          }
          banner {
            document {
              ... on PrismicBlindcvRichHeading {
                id
                data {
                  heading {
                    text
                    html
                  }
                  description {
                    text
                    html
                  }
                  primary_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                    alt
                  }
                  secondary_image {
                    localFile {
                      publicURL
                    }
                    alt
                  }
                }
              }
            }
          }
          investigation_container {
            document {
              ... on PrismicBlindcvInvestigationContainer {
                id
                data {
                  title
                  subtitle
                  rich_banner {
                    document {
                      ... on PrismicBlindcvRichHeading {
                        id
                        data {
                          heading {
                            text
                            html
                          }
                          description {
                            text
                            html
                          }
                          primary_image {
                            localFile {
                              childImageSharp {
                                id
                                gatsbyImageData
                              }
                            }
                            alt
                          }
                          secondary_image {
                            localFile {
                              childImageSharp {
                                id
                                gatsbyImageData
                              }
                            }
                            alt
                          }
                        }
                      }
                    }
                  }
                  youtube_video {
                    document {
                      ... on PrismicBlindcvVideo {
                        id
                        data {
                          background_image {
                            localFile {
                              childImageSharp {
                                gatsbyImageData
                              }
                            }
                            alt
                          }
                          play_button_icon {
                            localFile {
                              childImageSharp {
                                gatsbyImageData
                              }
                            }
                            alt
                          }
                          play_button_label
                          transcript_file {
                            id
                            url
                          }
                          transcript_label
                          youtube_video_id
                        }
                      }
                    }
                  }
                  investigation_list {
                    investigation {
                      document {
                        ... on PrismicBlindcvInvestigationBanner {
                          id
                          data {
                            title
                            description
                            image_position
                            primary_image {
                              localFile {
                                publicURL
                              }
                              alt
                            }
                          }
                        }
                      }
                    }
                  }
                  background_color
                }
              }
            }
          }
          investigation_result_container {
            document {
              ... on PrismicBlindcvInvestigationResultContainer {
                id
                data {
                  investigation_results {
                    investigation_result {
                      document {
                        ... on PrismicBlindcvInvestigationResult {
                          id
                          data {
                            title {
                              text
                              html
                            }
                            sub_title
                            primary_image {
                              localFile {
                                publicURL
                              }
                              alt
                            }
                            primary_image_position
                            results {
                              description
                              percentage {
                                text
                                html
                              }
                            }
                            right_description
                            right_percentage {
                              text
                              html
                            }
                            icon_image {
                              localFile {
                                childImageSharp {
                                  gatsbyImageData
                                }
                              }
                              alt
                            }
                            secondary_image {
                              localFile {
                                childImageSharp {
                                  gatsbyImageData
                                }
                              }
                              alt
                            }
                            lottie_animation {
                              localFile {
                                publicURL
                                url
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  investigation_summary {
                    document {
                      ... on PrismicBlindcvInvestigationSummary {
                        id
                        data {
                          title {
                            text
                            html
                          }
                          description
                          label
                          percentage {
                            text
                            html
                          }
                          image {
                            localFile {
                              publicURL
                            }
                            alt
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          investigation_tools_container {
            document {
              ... on PrismicBlindcvInvestigationTool {
                id
                data {
                  tool_download {
                    document {
                      ... on PrismicBlindcvInvestigationToolDownload {
                        id
                        data {
                          title {
                            text
                            html
                          }
                          description {
                            text
                            html
                          }
                          primary_image {
                            localFile {
                              publicURL
                            }
                            alt
                          }
                          icon_1 {
                            localFile {
                              childImageSharp {
                                gatsbyImageData
                              }
                            }
                            alt
                          }
                          download_icon {
                            localFile {
                              childImageSharp {
                                gatsbyImageData
                              }
                            }
                            alt
                          }
                          download_label
                          download_link
                        }
                      }
                    }
                  }
                  tool_explore {
                    document {
                      ... on PrismicBlindcvInvestigationExploreTool {
                        id
                        data {
                          title {
                            text
                            html
                          }
                          label {
                            text
                            html
                          }
                          image {
                            localFile {
                              childImageSharp {
                                gatsbyImageData
                              }
                            }
                            alt
                          }
                          link
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const investigationBanner =
    data.prismicBlindcvInvestigationPage.data.banner.document.data
  const investigationDetails =
    data.prismicBlindcvInvestigationPage.data.investigation_container
  const investigationResults =
    data.prismicBlindcvInvestigationPage.data.investigation_result_container
  const investigationTool =
    data.prismicBlindcvInvestigationPage.data.investigation_tools_container
      .document.data

  return (
    <div>
      <Layout
        pageViewAnalyticsData={
          data.prismicBlindcvInvestigationPage.data.analytics.document.data
        }
      >
        <NavigationBar />
        <div className="page-content">
          <InvestigationIntro data={investigationBanner} />
          <InvestigationDetails data={investigationDetails} />
          <InvestigationResults data={investigationResults.document.data} />
          <InvestigationCVTool data={investigationTool} />
          <SiteFooter />
        </div>
      </Layout>
    </div>
  )
}
export default Investigation
