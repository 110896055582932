import React from "react"
import InvestigationTiles from "./InvestigationTiles/InvestigationTiles"
import JSONAnimationHover from "../JSONAnimationHover/JSONAnimationHover"
import RightArrow from "./../../images/Animations/Right_Arrow.json"
import "./InvestigationDetails.scss"

const InvestigationDetails = props => {
  return (
    <section className="investigationdetails">
      <JSONAnimationHover
        normal={RightArrow}
        className="bluerightarrow"
        hasHover={true}
        loop={0}
      />
      <h3 className="headingdesc">{props.data.document.data.title}</h3>
      <InvestigationTiles data={props} />
    </section>
  )
}
export default InvestigationDetails
