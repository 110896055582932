import React from "react"
import { Slide, Fade } from "react-reveal"
import { Markup } from "interweave"
import JSONAnimationHover from "../JSONAnimationHover/JSONAnimationHover"
import LighteningBolt from "./../../images/Animations/Lightening_bolt.json"
import "./InvestigationIntro.scss"

export default function InvestigationIntro(props) {
  return (
    <Slide top>
      <section className="investigationIntro">
        <div className="container">
          <div className="row">
            <div className=" col-12 col-md-8 col-lg-7">
              <Fade top delay={1000} duration={1000} distance={"100px"}>
                <h1>
                  <Markup content={props.data.heading.text}></Markup>
                </h1>
              </Fade>
            </div>
            <div className="col-md-4 col-lg-4 imageCenter test-image">
              <p>THE INVESTIGATION</p>
              <div className="card-image">
                <JSONAnimationHover
                  normal={LighteningBolt}
                  className="arrowImg"
                  loop={1}
                />
              </div>

              <div className="intro-image">
                <img
                  className="investimg"
                  src={props.data.secondary_image.localFile.publicURL}
                  alt={props.data.secondary_image.alt}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Slide>
  )
}
