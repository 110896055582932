import React, {useState, useEffect, useRef } from "react"
import { Parallax, ParallaxProvider } from "react-scroll-parallax"
import { Markup } from "interweave"
import Video from "../Video/Video"
import "./InvestigationTiles.scss"
import Subtractright from "../../../images/Subtractright.png"
import Subtractleft from "../../../images/Subtractleft.png"
import JSONAnimationHover from "../../JSONAnimationHover/JSONAnimationHover"
import RightArrow from "./../../../images/Animations/Right_Arrow.json"
import LeftArrow from "./../../../images/Animations/Left_Arrow.json"
import Exclamation from "./../../../images/Animations/ExclamationMarks.json"


const Tile = ({value, index, hasArrow}) => {
 const arrowImage = `blue${value.investigation.document.data.image_position}arrow`
 const [pause, setPause] = useState(true)

 useEffect(() => {
  const arrow = document.querySelector('.tiles.tile-' + index);
  const observer = new IntersectionObserver((entries) => {
    if(entries[0].isIntersecting) setPause(false);
  });
  observer.observe(arrow);

}, [])


    return (
      <div className={`tiles ${value.investigation.document.data.image_position} tile-${index}`} >
        <div className="row">
          <div className="col-12 col-md-10 col-lg-10">
            <ParallaxProvider>
              <Parallax speed={-3}>
                <div className={`insetimage ${value.investigation.document.data.image_position}`}>
                  <img className="Image" src={value.investigation.document.data.primary_image.localFile.publicURL} alt="{value.investigation.document.data.primary_image.alt}" />
                </div>
              </Parallax>
            </ParallaxProvider>
            <h3>{value.investigation.document.data.title}</h3>
            <p>{value.investigation.document.data.description}</p>
          </div>
        </div>
        { hasArrow && !pause?  <JSONAnimationHover className={arrowImage}  loop={1} waitToPlay={500}  normal={arrowImage === "blueLeftarrow" ? RightArrow : LeftArrow} />: <></> }
      </div>
    )
}


const Tiles = data => {
  let tilesSection = data.data.map((value, i, arr) => <Tile value={value} key={i} index={i} hasArrow={i !== arr.length - 1} />);
  return tilesSection
}

const InvestigationTiles = props => {
  const [pause, setPause] = useState(true)
  const firstTileRef = useRef();


  useEffect(() => {
   const observer = new IntersectionObserver((entries) => {
     if(entries[0].isIntersecting) setPause(false);
   });
  
   observer.observe(firstTileRef.current);

   return () => {
    observer.disconnect()
  }
 }, [])


  return (
    <section className="investigation-tiles">
      <Video data={props.data.data.document} />
      
      <div className="tiles first-tile" ref={firstTileRef}>
        <div className="row">
          <div className="col-12 col-md-7 col-lg-7">
            <img src={Subtractright} className="eclipsecut right" alt="" />
            { (!pause)? <JSONAnimationHover normal={Exclamation} className="exclamationmark" hasHover={true} waitToPlay={500} loop={0} /> : <></>}
            <h3><Markup content={props.data.data.document.data.rich_banner.document.data .heading.text } /> </h3>
            <img src={Subtractleft} className="eclipsecut left" alt="" />
          </div>
          <div className="col-12 col-md-4 col-lg-5">
            <p><Markup content={props.data.data.document.data.rich_banner.document.data.description.text}/></p>
          </div>
        </div>
        { (!pause)? <JSONAnimationHover normal={LeftArrow} className="blueRightarrow" hasHover={true} waitToPlay={500}  loop={0} />: <></>}
      </div>
      
      <Tiles data={props.data.data.document.data.investigation_list} />
    </section>
  )
}

export default InvestigationTiles
