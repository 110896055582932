import React, { useState } from "react"
import { getImage } from "gatsby-plugin-image"
import "./Video.scss"
import YoutubeEmbed from "../Video/YoutubeEmbed/YoutubeEmbed"

const Video = props => {
  const [showVideo, setShow] = useState(false)
  const handleShow = () => {
    setShow(true)
  }
  return (
    <section className="video-wrapper">
      <p className="headingp">{props.data.data.subtitle}</p>
      <div className="youtube-video">
        <div className="poster-image">
          <button className="playBtn play" onClick={handleShow}>
            <svg
              width="212"
              height="176"
              viewBox="0 0 212 176"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_3542_22927)">
                <circle cx="106" cy="119" r="51" fill="white" />
                <path
                  d="M155.42 83.1897C141.73 72.6897 128.36 60.4397 108.86 63.1897C99.7298 62.0997 93.6599 62.0197 85.6699 67.4997C49.0199 74.6397 33.6698 135.83 62.6098 159.25C81.4698 171.77 112.61 184.25 131.48 165.95C142.48 159.95 154.67 151.89 158.36 138.85C161.43 128.12 165.99 88.7997 155.42 83.1897ZM142.2 127.19C141.51 141.19 130.37 150.19 118.25 153.89C102.25 162.82 82.4 154.67 70.04 143.06C62.04 136.14 66.19 114.34 68.04 104.57C85.01 62.5397 158.75 78.8397 142.2 127.15V127.19Z"
                  fill="#FF67F4"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M115.4 120.19C118.93 123.65 125.579 128.37 130.159 128.64C132.919 124.64 137.35 119.27 138.04 115.27C126.96 106.61 122.33 105.79 111.42 97.1599C105.082 92.2964 97.8623 88.7069 90.1594 86.5898C88.7594 87.0298 90.4996 86.7498 89.3796 88.1298C88.3796 87.8798 88.1695 88.7099 87.5095 89.2999C87.0195 90.8499 84.2594 95.8098 83.9294 95.5098C83.6294 95.9098 82.0694 98.2898 82.6794 97.9698C81.4094 99.4098 82.6798 98.4999 82.5798 99.4899C81.2998 100.08 82.1594 100.7 82.9094 99.6699C80.2294 102.06 86.9798 102.4 88.8098 103.92C95.3198 106 100.499 109.92 103.659 112.42C104.209 113.6 106.66 115.07 107.84 115.18C107.7 115.3 107.99 114.63 107.98 114.62C107.38 116.32 109.23 114.94 108.48 115.87C112.72 117.97 110.89 118.8 115.4 120.19ZM119.65 105.89C120.32 105.26 119.749 104.89 119.389 105.73C119.069 106.24 119.39 105.1 119.67 104.93C113.67 101.38 127.419 107.93 129.119 111.42C127.189 110.76 125.639 108.27 124.889 109.42C125.139 108.64 121.44 105.8 119.61 105.89H119.65ZM117.27 103.45C112.784 99.6763 107.956 96.3285 102.85 93.4498C107.72 95.1798 114.7 99.6098 117.27 103.45ZM102.389 93.5198C101.009 92.5198 94.6998 88.5199 94.0598 90.1199C95.1098 88.0399 100.31 92.2399 101.06 91.4999C100.33 92.0899 102.839 92.4998 103.179 92.6898C103.169 93.3798 101.839 93.8598 102.389 93.5198Z"
                  fill="#55DBFF"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M126.61 128.63C134.84 123.11 138.61 121.47 139.93 124.4C140.03 124.61 134.37 113.84 126.82 108.8C117.35 116.8 115.42 118.45 103.76 123.86C96.52 127.47 89.2102 133.38 85.2302 139.35C85.1202 140.11 86.8402 140.24 86.2302 141.85C87.6002 143.48 91.79 147.46 91.59 148.14C91.87 148.56 93.59 150.79 93.51 150.14C93.97 151.24 94.2298 150.49 94.6198 151C94.7098 150.85 94.0398 150 94.8898 150.78C95.0498 152.09 95.8901 151.63 95.1501 150.55C96.0501 152.64 97.1498 151.2 97.8898 149.69C97.8898 150.41 99.89 147.38 100.76 146.94C103.28 142.69 109.02 141.86 110.59 138.56C110.84 139.33 112.59 138.11 113.88 137.67C113.8 137.09 113.75 136.91 114.07 137.55C115.751 137.273 117.269 136.382 118.33 135.05C118.38 135.23 117.91 134.68 117.89 134.69C119.46 136.11 118.54 133.58 119.23 134.79C123.02 131.87 123.44 132.47 126.61 128.63ZM114.86 120.45C113.78 118.75 115.27 122.14 114.01 120.02C108.6 123.87 119.52 114.52 123.15 114.29C121.84 115.77 119.15 116.16 119.96 117.29C119.61 116.66 115.33 118.97 114.9 120.45H114.86ZM111.79 121.52C106.502 123.795 101.473 126.632 96.7902 129.98C100.37 126.33 107.38 122.03 111.83 121.52H111.79ZM96.6398 130.43C95.1898 131.27 88.7303 135.57 90.0603 136.81C88.4703 134.94 94.5702 131.81 94.2102 130.81C94.4602 131.71 95.9302 129.57 96.2102 129.32C96.8902 129.66 96.7599 131.09 96.6799 130.43H96.6398Z"
                  fill="#55DBFF"
                />
                <path
                  d="M132.96 124.7C134.297 122.893 135.516 121.003 136.61 119.04C134.25 115.66 130.82 111.45 126.86 108.8L126.71 108.92C127.673 109.583 128.492 110.433 129.12 111.42C128.038 110.884 127.03 110.212 126.12 109.42C120.39 114.25 117.45 116.69 113.02 119.23C113.318 119.403 113.629 119.554 113.95 119.68C116.3 117.79 121.03 114.43 123.19 114.29C121.88 115.77 119.19 116.16 120 117.29C119.65 116.72 116 118.61 115.07 120.1L115.4 120.21C118.29 123.04 123.26 126.71 127.49 128.1C129.61 126.67 131.43 125.53 132.96 124.7Z"
                  fill="#1DC6FF"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M99.1501 98.5303C95.7001 99.6703 89.9599 98.5303 82.6799 99.2303C81.6799 99.3203 83.0102 107.66 83.4802 109.17C82.4802 111.66 83.5499 119.68 82.6799 123.49C81.9599 128.32 82.7898 134.42 84.6198 137.96C85.2298 138.53 94.1299 137.96 95.4699 138.32C95.9599 138.32 98.7999 138.41 98.2199 138.18C98.4799 138.18 99.7403 138.41 99.0403 137.94C100.25 137.94 98.2603 137.85 99.5803 137.72C99.7503 137.78 100.46 138.17 100.68 137.66C99.6799 137.74 100.36 137.45 99.57 137.48C100.57 137.36 100.45 137.73 100.95 137.55C100.71 137.55 101.95 137.08 100.66 137.09C101.59 136.82 101 135.43 100.33 135.72C101.56 135.02 99.5103 131.94 99.3303 130.18C99.6703 129.89 99.9003 125.18 98.8503 125.69C99.8503 125.14 99.7 124.55 98.76 124.2C99.4 124.2 99.0603 124.2 99.5803 124.09C98.1603 123.67 101.03 122.02 99.1799 121.85C100.65 122.14 100.31 119.07 99.82 118.59C99.99 118.59 99.2702 118.73 99.2702 118.74C100.35 117.97 99.1501 101.97 99.1501 98.5303ZM85.5202 116.27C83.4502 116.41 87.2899 116.5 84.7199 116.63C85.7199 117.16 84.2601 117.74 84.8601 118.23C83.9401 116.82 83.4201 112.42 84.6301 109.67C84.4101 110.37 85.38 111.21 84.55 111.73C86.26 111.55 83.1602 112.62 85.4402 112.41C85.2657 112.458 85.1101 112.559 84.9948 112.698C84.8796 112.838 84.8103 113.009 84.7961 113.19C84.7819 113.37 84.8238 113.55 84.9157 113.706C85.0077 113.862 85.1455 113.986 85.3103 114.06C84.8903 113.97 84.3902 115.91 85.5202 116.27ZM84.8601 118.33C84.0886 121.851 83.8261 125.465 84.0803 129.06C82.9303 126.26 83.1701 120.16 84.8601 118.33Z"
                  fill="#55DBFF"
                />
                <path
                  d="M96.8198 129.97C97.6859 129.107 98.6044 128.299 99.5698 127.55C99.5667 127.132 99.5165 126.716 99.4199 126.31C94.2912 129.43 89.7715 133.455 86.0801 138.19C88.8101 138.33 94.4197 138.06 95.4697 138.31C95.9597 138.31 98.7997 138.4 98.2197 138.17C98.4797 138.17 99.74 138.4 99.04 137.93C100.25 137.93 98.2601 137.84 99.5801 137.71C99.7401 137.77 100.46 138.16 100.68 137.65C99.6797 137.73 100.36 137.44 99.5698 137.47C100.57 137.35 100.45 137.72 100.95 137.54C100.71 137.54 101.95 137.07 100.66 137.08C101.59 136.81 101 135.42 100.33 135.71C101.56 135.01 99.5101 131.93 99.3301 130.17C99.5572 129.511 99.6521 128.815 99.6099 128.12C98.7099 128.7 97.7998 129.31 96.8198 129.97ZM96.6797 130.42C95.3297 131.21 89.5799 135.02 89.9399 136.53C89.0899 134.64 94.59 131.8 94.25 130.85C94.5 131.75 95.97 129.61 96.25 129.36C96.89 129.66 96.7597 131.09 96.6797 130.43V130.42Z"
                  fill="#1DC6FF"
                />
                <path
                  d="M82.6797 99.2296C82.6797 99.2296 82.6101 99.2296 82.5801 99.2996C82.5851 99.3628 82.5851 99.4263 82.5801 99.4896L82.4199 99.5795C82.3691 99.769 82.3391 99.9635 82.3301 100.16C82.4323 100.124 82.5269 100.069 82.6099 99.9996C82.4266 100.188 82.3199 100.437 82.3101 100.7C82.3701 102.2 87.3101 102.7 88.8101 103.91C92.6565 105.196 96.2923 107.042 99.6001 109.39C99.4401 104.93 99.1499 100.21 99.1499 98.5195C95.6999 99.6695 89.9597 98.5296 82.6797 99.2296Z"
                  fill="#1DC6FF"
                />
                <path
                  className="icon-left-tip-3"
                  d="M55.6098 24.7502C54.4208 23.7801 52.9991 23.1378 51.4853 22.8869C49.9714 22.6361 48.4182 22.7855 46.9799 23.3202C44.9099 23.9402 40.9796 24.3202 39.9096 26.4802C39.2296 27.8202 40.69 31.1502 41.29 32.5802C42.41 35.2602 45.0999 37.5802 46.9599 39.7302C50.8599 44.3002 54.9598 48.6802 59.1698 52.9802C62.5998 56.4902 65.65 55.2202 69.56 52.6702C71.56 51.6702 72.8598 51.5402 73.6498 49.4902C73.9617 48.8766 74.1048 48.1912 74.0644 47.5041C74.024 46.817 73.8015 46.153 73.4198 45.5802C72.0798 42.8302 69.5396 40.0902 67.6596 37.6902C64.0896 33.2002 60.2598 28.1302 55.6098 24.7502Z"
                  fill="#FF67F4"
                />
                <path
                  className="icon-middle-tip"
                  d="M105.54 5.7399C104.15 1.7399 96.22 -2.2601 92.99 1.5499C89.71 3.6099 90.0499 8.5999 89.9299 11.9899C89.8733 16.3022 90.1576 20.6123 90.78 24.8799C91.31 29.1399 92 33.3699 92.55 37.6199C93.1 41.8699 93.7599 44.7499 98.6999 44.6799C101.62 44.6799 105.47 43.5699 107.31 41.1199C109.15 38.6699 108.49 34.5699 108.38 31.5799C108.07 23.1799 108.32 13.7499 105.54 5.7399Z"
                  fill="#FF67F4"
                />
                <path
                  className="icon-right-tip-2"
                  d="M188.3 64.6499C191.99 62.7999 194.129 59.8299 197.389 57.5399C199.059 55.0699 198.739 52.7399 196.909 50.5399C196.778 50.5762 196.653 50.6336 196.54 50.7099C196.22 50.0099 197.21 48.7899 195.69 48.4599C195.42 46.4599 194.86 45.9599 193.24 44.7099H193.159L191.159 44.1599C188.369 41.1599 182.3 46.9799 179.67 48.3899C177.04 49.7999 174.52 50.6199 172.51 53.0899C170.5 55.5599 168.36 57.9399 166.28 60.4599C163.28 64.1399 161.55 67.1499 163.5 71.7699C163.804 72.5345 164.176 73.2707 164.61 73.9699C165.367 74.7431 166.258 75.3731 167.24 75.8299C169.026 77.0393 171.185 77.5745 173.33 77.3399C175.94 76.9399 177.12 74.8999 178.99 73.2499C182.08 70.5199 185.99 68.2499 188.25 64.7099L188.3 64.6499Z"
                  fill="#FF67F4"
                />
                <path
                  className="icon-right-tip-3"
                  d="M157.61 22.5104C155.67 18.4104 154.55 16.4104 150.71 14.0604C148.15 12.4904 146.18 11.1104 143.13 12.4404C140.13 14.4404 138.86 18.4404 137.45 21.5804C135.89 25.0604 134.39 28.5804 132.76 32.0004C131.146 35.6451 129.932 39.4541 129.139 43.3605C128.329 47.2805 131.94 51.2104 135.47 52.2804C138.23 53.1304 141.97 53.6904 144.53 51.9604C147.36 50.0604 148.53 45.7704 149.98 42.8704C151.68 39.5804 154.14 37.4504 155.27 33.8704C155.87 31.9904 156.77 30.2305 157.36 28.3605C157.99 26.2805 157.47 24.5704 157.61 22.5104Z"
                  fill="#FF67F4"
                />
                <path
                  className="icon-left-tip-2"
                  d="M45.6998 63.3402C42.5398 58.0902 35.02 56.2702 29.86 53.7002C27.21 52.3802 25.1498 50.7702 22.4098 49.8302C21.2698 49.4302 20.2698 48.4302 19.4098 48.0602C18.0598 47.5002 17.0698 48.0602 15.6598 47.9902C14.5698 49.9302 12.5601 51.2202 11.5201 53.1802C10.2601 55.5702 11.2699 56.7702 12.0499 59.0802C12.9099 61.5802 12.48 64.2102 14.15 66.4402C15.82 68.6702 19.1501 69.6702 21.7501 70.8202C25.0834 72.3202 28.4466 73.8135 31.84 75.3002C34.2 76.3002 36.9698 78.1802 39.6398 77.5302C44.8298 76.2602 46.9801 67.7602 45.7301 63.3402H45.6998Z"
                  fill="#FF67F4"
                />
                <path
                  className="icon-left-tip-1"
                  d="M36.1796 97.0504C33.7296 93.8904 29.3596 93.1104 25.6596 92.3204C21.2096 91.3204 16.6596 91.1804 12.1196 90.7404C9.21956 90.4604 4.8397 89.0304 2.2597 91.1204C-0.0903003 93.0404 -0.170437 97.4004 0.119563 100.12C0.539563 104.06 2.79001 105.84 6.33001 107.12C10.76 108.77 15.3297 110.4 20.0497 110.8C23.8397 111.13 28.4997 110.98 31.7197 108.68C33.5022 107.371 34.8708 105.576 35.6616 103.511C36.4524 101.445 36.632 99.1955 36.1796 97.0304V97.0504Z"
                  fill="#FF67F4"
                />
                <path
                  className="icon-right-tip-1"
                  d="M210.08 94.5496C209.71 91.8496 208.4 87.8296 205.56 86.7496C202.87 85.7496 198.51 88.5496 196.13 89.5296L184.79 94.1696C181.97 95.3196 176.91 96.3996 175.01 99.0296C172.86 102.03 175.12 103.55 176.71 105.95C178.99 109.38 180.19 111.32 184.55 111.95C189.07 112.57 191.34 110.95 195.34 109.56C196.67 109.09 198.16 109.11 199.5 108.56C200.33 108.23 202.42 106.26 203.11 106.26L205.65 105.58C206.383 105.434 207.055 105.07 207.577 104.534C208.098 103.998 208.444 103.317 208.57 102.58C211.02 100.97 211.84 96.8496 210.08 94.5496Z"
                  fill="#FF67F4"
                />
              </g>
            </svg>
            <p onClick={handleShow}>
              <span>
                {props.data.data.youtube_video.document.data.play_button_label}
              </span>
            </p>
          </button>
        </div>
        <div className={`video ${showVideo ? "show" : "hide"}`}>
          <YoutubeEmbed
            playing={showVideo}
            data={props.data.data.youtube_video}
          />
        </div>
        <div className="transcript">
          <a
            href={
              props.data.data.youtube_video.document.data.transcript_file.url
            }
            className=""
            target="_blank"
            rel="noreferrer"
          >
            {props.data.data.youtube_video.document.data.transcript_label}
          </a>
        </div>
      </div>
    </section>
  )
}

export default Video
