import React from "react"
import "./YoutubeEmbed.scss"
import ReactPlayer from "react-player"
import useCookie from "../../../../hooks/useCookie"

const YoutubeEmbed = props => {

  const [userConsentCookie] = useCookie(process.env.GATSBY_USER_CONSENT_COOKIE);
	// const videoConsentURL = (userConsentCookie === 'accept')?"https://www.youtube.com/embed/":"https://www.youtube-nocookie.com/embed/";
  const videoConsentURL = "https://www.youtube.com/embed/";
  const videoNoConsentURL = "https://www.youtube-nocookie.com/embed/";

  return (
    <section className="embed">
      <iframe data-src={`${videoConsentURL}${props.data.document.data.youtube_video_id}`} 
              src={`${videoNoConsentURL}${props.data.document.data.youtube_video_id}`} 
              className="optanon-category-C0003" controls="true" width="100%" height="100%"></iframe>


      {/* <ReactPlayer
        playing={props.playing}
        url={`${videoConsentURL}${props.data.document.data.youtube_video_id}`}
        controls="true"
        width="100%"
        height="100%"
        className="optanon-category-C0002"
        
        config={{
          file: {
            tracks: [
              {
                kind: "subtitles",
                src: "../../transcript/transcript.srt",
                srcLang: "en",
                default: true,
              },
            ],
          },
        }}
      /> */}
    </section>
  )
}

export default YoutubeEmbed
