import React, {useState, useEffect, useRef} from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Markup } from "interweave"
import "./InvestigationCVTool.scss"
import Subtractright from "./../../images/Subtractright.png"
import Subtractleft from "./../../images/Subtractleft.png"
import Subtractblueright from "./../../images/Subtractrightblue.png"
import Subtractblueleft from "./../../images/Subtractleftblue.png"
import JSONAnimationHover from "../JSONAnimationHover/JSONAnimationHover"
import Download from "./../../images/Animations/Download.json"
import Builder from "./../../images/Animations/Builder.json"
import RightArrow from "./../../images/Animations/Left_Arrow.json"
import { Link } from "gatsby"


export default function InvestigationCVTool(props) {
  const [pause, setPause] = useState(true);
  const arrowRef = useRef();


  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if(entries[0].isIntersecting) setPause(false);
    });
    observer.observe(arrowRef.current);
    return () => {
      observer.disconnect()
    }
  }, [])

  const iconYes = getImage(props.data.tool_download.document.data.icon_1.localFile.childImageSharp.gatsbyImageData)

  return (
    <section className="investigation-Tool">
      <div className="container container-sm" >
        <span ref={arrowRef}>
        { (!pause)? <JSONAnimationHover normal={RightArrow} className="blue-right-arrow" hasHover={true} loop={0}  waitToPlay={500}/>: <></>}
        </span>
        <h2><Markup content={props.data.tool_download.document.data.title.text} /></h2>
        <div className="row summary-content">
          <div className="col-12 col-md-12 col-lg-8 card-content">
            <img src={Subtractright} alt="Right arrow" className="eclipsecut right" />
            <img src={Subtractleft} alt="Left arrow" className="eclipsecut left"/>
            <div className="row card-1">
              <img className="main-img" src={props.data.tool_download.document.data.primary_image.localFile.publicURL } alt={props.data.tool_download.document.data.primary_image.alt}/>
              <p>
                <Markup content={props.data.tool_download.document.data.description.html } />
              </p>
              <div className="relative">
                <GatsbyImage className="yesImg" image={iconYes} alt={props.data.tool_download.document.data.icon_1.alt} />
                <div className="downloadImg">
                  <Link
                    to={props.data.tool_download.document.data.download_link}
                    className="download-cta"
                    data-analytics={`{"event":"cta_interaction",
                      "click_text":"Check out the anon cv tool",
                      "destination_url":"${props.data.tool_download.document.data.download_link}",
                      "click_type":"Internal",
                      "module":"Investigation CV Tool"
                    }`}
                  >
                    <JSONAnimationHover normal={Download} className={`arrowImg item-img download-arrow`} hasHover={true} loop={1} />

                    <span className="button link-green"> Check out the <br /> anon cv tool </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 card-2">
            <Link
              className="card-hover"
              to={props.data.tool_explore.document.data.link}
              data-analytics={`{"event":"cta_interaction",
                "click_text":"Explore the cv builder",
                "destination_url":"${props.data.tool_explore.document.data.link}",
                "click_type":"Internal",
                "module":"Investigation CV Tool"
              }`}
            >
              <div className="row">
                <div className="col-6 col-lg-11 h3-set">
                  <img src={Subtractblueright} className="eclipsecut left" alt="" />
                  <img src={Subtractblueleft} className="eclipsecut right" alt="" />
                  <h3><Markup content={props.data.tool_explore.document.data.title.text} /></h3>
                </div>
                <div className="col-6 col-lg-12 col-md-6">
                  <div className="image-block">
                    <JSONAnimationHover normal={Builder} className={`arrowImg item-img swapImg`} hasHover={true} loop={1} />
                    <Markup content={props.data.tool_explore.document.data.label.text} />
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}
