import {useState} from 'react';
import Cookies from 'universal-cookie';

const useCookie = (key, options = {}) => {
  const cookies = new Cookies();
  const [item, setItemValue] = useState(() => {
    if (cookies.get(key)) {
      return cookies.get(key);
    }
    return null;
  });

  const setValue = (value, options) => {
	let localOptions =   { path: '/' }
	let newOptions = {...localOptions, ...options};
	
    setItemValue(value);
    cookies.set(key, value, newOptions);
  };

  const removeItem = () => {
    cookies.remove(key);
  };

  const getItem = (key) => {
    cookies.get(key)
  };

  return [item, setValue, removeItem, getItem];
};

export default useCookie;
