import React, {useState, useRef, useEffect} from "react"
import "./InvestigationResults.scss"
import { Markup } from "interweave"
import greaterblue from "./../../images/greater-blue.png"
import JSONAnimationHover from "../JSONAnimationHover/JSONAnimationHover"
import Exclamation from "./../../images/Animations/ExclamationMarks.json"
import SixLines from "./../../images/Animations/Only6_Expression.json"
import TextHighlights from "./../global/TextHighlights/TextHighlights";


const ResultSummary = ({data, index}) => { 
  const [pause, setPause] = useState(true);
  const summaryRef = useRef();


  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if(entries[0].isIntersecting) setPause(false);
    });
    observer.observe(summaryRef.current);

    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <div className={`col-8 col-lg-4 col-md-4 place-center six ${data.primary_image_position}`} ref={summaryRef}>
       { (!pause)? <JSONAnimationHover normal={(index === 0)?Exclamation:SixLines} className={`semimark ${data.primary_image_position}`} hasHover={true} waitToPlay={500} loop={0} />: <></>}
      <div><span className="zero six">{ data.right_percentage.text }</span></div>
      <p>{data.right_description}</p>
    </div>
  )

}


const ResultsTiles = ({data}) => {
  return (
    data.map((value, i, arr) => {

    return (
      <div className={`row ${value.investigation_result.document.data.primary_image_position}`} key={i}>
        <div className="col-lg-4 image3-center image-center">
          <img className={`image3 ${value.investigation_result.document.data.primary_image_position}`}
            src={value.investigation_result.document.data.primary_image.localFile.publicURL}
            alt="{value.investigation_result.document.data.primary_image.alt}"
          />
        </div>
        <div className="col-lg-8">
          <div className="row">
            <h3>{value.investigation_result.document.data.sub_title}</h3>
            <div className="col-12 col-lg-6 col-md-6">
              {value.investigation_result.document.data.results.map((data, i, arr) => {
                  return (
                    <div className="mb-3" key={i}>
                      <div className="row g-0">
                        <div className="col-md-4 col-lg-4 col-4">
                          <h2><Markup content={data.percentage.text} /></h2>
                        </div>
                        <div className="col-md-8 col-lg-7 col-8">
                          <div className=""><p><Markup content={data.description} /></p></div>
                        </div>
                      </div>
                      {i === arr.length - 1 ? <></>: <hr className="row-hr " /> }
                    </div>
                  )
                }
              )}
            </div>
            <div className="col-1 col-lg-1 col-md-1 curly-bracket curly-bracket-two"></div>
            <ResultSummary index={i} data={value.investigation_result.document.data} />
          </div>
        </div>

        <hr className="hr-line" />
      </div>
    )
  })
  )
}

export default function InvestigationResults(props) {
  const [pause, setPause] = useState(true);
  const highlightRef = useRef();


  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if(entries[0].isIntersecting) setPause(false);
    });
    observer.observe(highlightRef.current);

    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <section className="investigation-result">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-8">
            <h1><Markup content={ props.data.investigation_results[0].investigation_result.document.data.title.text}/></h1>
          </div>
        </div>
        <ResultsTiles data={props.data.investigation_results} />
        <div className="row summary">
          <div className="col-lg-8 col-md-12 order-lg-1 order-sm-2 order-2 ">
            <h2><Markup content={props.data.investigation_summary.document.data.title.text} /></h2>
            <p>{props.data.investigation_summary.document.data.label}</p>
            <div className="row">
              <div className="col-lg-5 col-md-5 place-center three" ref={highlightRef}>
              { (!pause)? <TextHighlights  content={props.data.investigation_summary.document.data.percentage.text} cssClass="zero"  waitToPlay={500}  />: <></>}

                
              </div>
              <div className="col-lg-7 col-md-7">
                <div className="row">
                  <div className="col-1 col-lg-2 col-md-2 greaterblue">
                    <img src={greaterblue} alt="alt logo" className="greater-blue" />
                  </div>
                  <div className="col-10 col-lg-10 col-md-10 ">
                    <p className="sum-para">{props.data.investigation_summary.document.data.description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 order-lg-2 order-sm-1 order-1 image-center">
            <img className="image2" src={props.data.investigation_summary.document.data.image.localFile.publicURL} alt="" />
          </div>
        </div>
      </div>
    </section>
  )
}
